const SESSON_KEY_GLOBAL_MODULEID = "GLOBAL_MODULEID";
const SESSON_KEY_STATUTE_SUBJECTID = "STATUTE_SUBJECTID";
const SESSON_KEY_CLOUSE_NODEID = "CLOUSE_NODEID";
const SESSON_KEY_CLOUSE_NODENAME = "CLOUSE_NODENAME";
const SESSON_KEY_QUESTION_SELKNOWLEDGES = "QUESTION_SELKNOWLEDGES";
const SESSON_KEY_AD_POSITIONID = "AD_POSITIONID";
const SESSON_KEY_RESOURE_COURSEID = "RESOURCE_COURSEID";
const SESSON_KEY_TEAMBUY_SELCOURSE = "TEAMBUY_SELCOURSE";
const SESSON_KEY_INTEGRAL_SELCOURSE = "INTEGRAL_SELCOURSE";
const SESSON_KEY_INTEGRAL_PRODUCT_SELREID = "INTEGRAL_PRODUCT_SELREID";
export {
    SESSON_KEY_GLOBAL_MODULEID,
    SESSON_KEY_STATUTE_SUBJECTID,
    SESSON_KEY_CLOUSE_NODEID,
    SESSON_KEY_CLOUSE_NODENAME,
    SESSON_KEY_QUESTION_SELKNOWLEDGES,
    SESSON_KEY_AD_POSITIONID,
    SESSON_KEY_RESOURE_COURSEID,
    SESSON_KEY_TEAMBUY_SELCOURSE,
    SESSON_KEY_INTEGRAL_SELCOURSE,
    SESSON_KEY_INTEGRAL_PRODUCT_SELREID
}