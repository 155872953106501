const API_VERSION = "/v2.4";

const UrlEnum = {
    ADMINS:                     API_VERSION + "/admin/admins",
    ADMINS_PASSWORD:            API_VERSION + "/admin/admins/password",
    ADMIN_AUTHORIZATION:        API_VERSION + "/admin/admins/authorization",
    ADMIN_MY_PROJECT:           API_VERSION + "/admin/admins/my/project",
    ADS:                        API_VERSION + "/admin/ads",
    AD_POSITIONS:               API_VERSION + "/admin/ad/positions",
    BRANCHES:                   API_VERSION + "/admin/branches",
    BAIJIA_VOD_VIDEOS:          API_VERSION + "/admin/baijia/vod/videos",
    BAIJIA_VOD_TOKEN:           API_VERSION + "/admin/baijia/vod/token",
    BAIJIA_CLASS_QUERY:         API_VERSION + "/baijia/callback/class/url",
    BAIJIA_TRANSCODE_QUERY:     API_VERSION + "/baijia/callback/transcode/url",
    BAIJIA_CLASS_SET:           API_VERSION + "/baijia/callback/class/set",
    BAIJIA_TRANSCODE_SET:       API_VERSION + "/baijia/callback/transcode/set",
    ASSISTANT:                  API_VERSION + "/admin/assistants",
    CHAPTER:                    API_VERSION + "/admin/chapters",
    CHAPTER_DROPDOWN:           API_VERSION + "/admin/chapters/dropdown",
    CHAPTER_TREE:               API_VERSION + "/admin/chapters/tree",
    CLASS:                      API_VERSION + "/admin/classes",
    COURSE:                     API_VERSION + "/admin/courses",
    COURSE_CATALOG:             API_VERSION + "/admin/course/catalogs",
    COURSE_CATALOG_CHECK_TREE:  API_VERSION + "/admin/course/catalog/check-tree",
    COURSE_CATALOG_TREE:        API_VERSION + "/admin/course/catalog-tree",
    COURSE_CATEGORY:            API_VERSION + "/admin/course/categorys",
    COURSE_CODE:                API_VERSION + "/admin/course/codes",
    COURSE_LESSON:              API_VERSION + "/admin/course/lessons",
    COURSE_LESSON_TREE:         API_VERSION + "/admin/course/lessons/tree",
    COURSE_QRCODE:              API_VERSION + "/admin/course/qrcode",
    COURSE_TASK_REPORT:         API_VERSION + "/admin/course/task-report",
    COURSE_TREE_DROPDOWN:       API_VERSION + "/admin/courses/tree/dropdown",
    COURSE_TREE_LEFT:           API_VERSION + "/admin/courses/tree/left",
    DISTRIBUTION_INCOMEREPORT:  API_VERSION + "/admin/distribution/income-report",
    DISTRIBUTION_INCOMERECORDS: API_VERSION + "/admin/distribution/income-records",
    DISTRIBUTION_PROMOTERS:     API_VERSION + "/admin/distribution/promoters",
    DISTRIBUTION_ACTIVITIES:    API_VERSION + "/admin/distribution/activities",
    DISTRIBUTION_PROFILE:       API_VERSION + "/admin/distribution/profile",
    DISTRIBUTION_RECORD:        API_VERSION + "/admin/distribution/records",
    EXAMS:                      API_VERSION + "/admin/exams",
    EXAM_ROUND:                 API_VERSION + "/admin/exam/rounds",
    EXPRESS_COMPANY:            API_VERSION + "/admin/express/companys",
    FEEDBACK:                   API_VERSION + "/admin/feedbacks",
    FINANCE_WITHDRAWS:          API_VERSION + "/admin/finance/withdraws",
    GIFTS:                      API_VERSION + "/admin/gifts",
    GIFTS_TREE:                 API_VERSION + "/admin/gifts/tree",
    INTEGRAL_TASKS:             API_VERSION + "/admin/integral/tasks",
    INTEGRAL_TYPES:             API_VERSION + "/admin/integral/types",
    INTEGRAL_PRODUCTS:          API_VERSION + "/admin/integral/products",
    KNOWLEDGE:                  API_VERSION + "/admin/knowledges",
    KNOWLEDGE_TREE:             API_VERSION + "/admin/knowledges/tree",
    LAW_CATALOGS:               API_VERSION + "/admin/law/catalogs",
    LAW_CATALOG_TREE:           API_VERSION + "/admin/law/catalog/tree",
    LAW_CLAUSES:                API_VERSION + "/admin/law/clauses",
    LAW_STATUTES:               API_VERSION + "/admin/law/statutes",
    LAW_TREE:                   API_VERSION + "/admin/law/clauses/tree",
    LEARN_WAYS:                 API_VERSION + "/admin/learn-ways",
    LESSON:                     API_VERSION + "/admin/lessons",
    LESSON_DAYS:                API_VERSION + "/admin/lessons/days",
    LESSON_LIVE_SIGN:           API_VERSION + "/admin/lessons/live/sign",
    LESSONS_PLAYBACK_TOKEN:     API_VERSION + "/admin/lessons/playback/token",
    LESSONS_VOD_TOKEN:          API_VERSION + "/admin/lessons/vod/token",
    MAJORS:                     API_VERSION + "/admin/majors",
    MASTERS:                    API_VERSION + "/admin/masters",
    MODULE_TREE:                API_VERSION + "/admin/modules/tree",
    ORDER:                      API_VERSION + "/admin/orders",
    ORDER_COMMENT:              API_VERSION + "/admin/order/comments",
    ORDER_EXPORT:               API_VERSION + "/admin/orders/export",
    ORDER_INVOICE:              API_VERSION + "/admin/order/invoices",
    ORDER_REPORT:               API_VERSION + "/admin/order/report",
    ORDER_STATUS:               API_VERSION + "/admin/order/statuses",
    PAPER:                      API_VERSION + "/admin/papers",
    PAPER_AUTO_QUESTIONS:       API_VERSION + "/admin/paper/auto-questions",
    PAPER_EXAMS:                API_VERSION + "/admin/paper/exams",
    PAPER_RULE_QUESTIONTYPES:   API_VERSION + "/admin/paper/rule/question-types",
    PAPER_RULE_SUBJECTS:        API_VERSION + "/admin/paper/rule/subjects",
    PAYMENT:                    API_VERSION + "/admin/payments",
    PAYMENT_EXPORT:             API_VERSION + "/admin/payments/export",
    PAYMENT_TYPE:               API_VERSION + "/admin/payment/types",
    PUSH_MESSAGE:               API_VERSION + "/admin/push/messages",
    QUESTION:                   API_VERSION + "/admin/questions",
    QUESTION_DIFFICULT:         API_VERSION + "/admin/question/difficults",
    QUESTION_FEEDBACK:          API_VERSION + "/admin/question/feedbacks",
    QUESTION_FEEDBACK_TYPE:     API_VERSION + "/admin/question/feedback/types",
    QUESTION_SIMILAR_WORDS:     API_VERSION + "/admin/question/similar-words",
    QUESTION_TYPE:              API_VERSION + "/admin/question/types",
    RECOMMEND:                  API_VERSION + "/admin/recommends",
    REFUND:                     API_VERSION + "/admin/refunds",
    REFUND_AMOUNT:              API_VERSION + "/admin/refund/amount",
    RESOURCE:                   API_VERSION + "/admin/resources",
    RESOURCE_CATEGORIES:        API_VERSION + "/admin/resource/categories",
    ROLE_MODULES:               API_VERSION + "/admin/role/modules",
    ROLES:                      API_VERSION + "/admin/roles",
    SEM:                        API_VERSION + "/admin/sems",
    SIGN_PROFILE:               API_VERSION + "/admin/sign/profile",
    SIGN_RULE:                  API_VERSION + "/admin/sign/rules",
    SOURCE:                     API_VERSION + "/admin/question/sources",
    SOURCES_TREE:               API_VERSION + "/admin/question/sources/tree",
    STUDENT:                    API_VERSION + "/admin/students",
    STUDENT_ADDRESS:            API_VERSION + "/admin/student/addresses",
    STUDENT_INTEGRAL_TASK:      API_VERSION + "/admin/student/integral-task",
    STUDENT_LEVEL:              API_VERSION + "/admin/student/levels",
    STUDENT_LESSON_RECORDS:     API_VERSION + "/admin/student/lesson-records",
    STUDENT_QUESTION_RECORDS:   API_VERSION + "/admin/student/question-records",
    STUDENT_SIGN_RECORE:        API_VERSION + "/admin/student/sign/records",
    STUDENT_TOKENS:             API_VERSION + "/admin/student/tokens",
    STUDENT_WITHDRAWS:          API_VERSION + "/admin/student/withdraws",
    SUBJECT:                    API_VERSION + "/admin/subjects",
    SUBJECT_VIDEOS:             API_VERSION + "/admin/subject/videos",
    PAPER_RULES:                API_VERSION + "/admin/paper/rules",
    PAPER_RULE_TYPES:           API_VERSION + "/admin/paper/rule/types",
    SYS_PROFILE:                API_VERSION + "/admin/sys/profile",
    TEACHER:                    API_VERSION + "/admin/teachers",
    TB_ACTIVITIES:              API_VERSION + "/admin/tb/activities",
    TB_TASKS:                   API_VERSION + "/admin/tb/tasks",
    UPLOAD_PIC:                 API_VERSION + "/admin/upload/pics",
    UPLOAD_POSTER:              API_VERSION + "/admin/upload/posters",
    UPLOAD_DOC:                 API_VERSION + "/admin/upload/docs",
    VERSION:                    API_VERSION + "/admin/versions",
    VOICE_QUESTION:             API_VERSION + "/admin/voice/questions",
    VOICE_ANSWER:               API_VERSION + "/admin/voice/answers",
    VOICE_ANSWER_UPLOAD:        API_VERSION + "/admin/voice/blob",
    UNIVERSITIES:               API_VERSION + "/admin/universities",
    UNIVERSITY_ADJUSTS:         API_VERSION + "/admin/university/adjusts",
    UNIVERSITY_AREAS:           API_VERSION + "/admin/university/areas",
    UNIVERSITY_CATEGORIES:      API_VERSION + "/admin/university/categories",
    UNIVERSITY_POLICIES:        API_VERSION + "/admin/university/policies",
    UNIVERSITY_TYPES:           API_VERSION + "/admin/university/types",
    UNIVERSITY_SCORES:          API_VERSION + "/admin/university/scores",
    YEARS:                      API_VERSION + "/admin/years"
};

const ErrorEnum = {
    //用户名不能为空
    BLANK_USERNAME: "BLANK_USERNAME",
    //密码不能为空
    BLANK_PASSWORD: "BLANK_PASSWORD",
    //验证码不能为空
    BLANK_VERCODE: "BLANK_VERCODE",
    //用户名已存在
    CONFLICT_USERNAME: "CONFLICT_USERNAME",

    FORBIDDEN: "FORBIDDEN",

    //没有管理权限
    FORBIDDEN_ADMIN: "FORBIDDEN_ADMIN",
    //用户名或密码错误
    INVALID_AUTHENTICATION: "INVALID_AUTHENTICATION",
    //无效的用户名
    INVALID_USERNAME: "INVALID_USERNAME",
    //未登录
    UNAUTHORIZED: "UNAUTHORIZED",
    //服务器异常
    INTERNAL_SERVER_ERROR: "Internal Server Error"
};

const TipEnum = {
    SUBMIT_SUCCESS: "提交成功",
    DELETE_SUCCESS: "删除成功",
    DELETE_CONFIRM: "确定删除此信息？",
    NO_SELECT: "请选择要删除的数据"
};

export const ActivityTypeEnum = {
    PROMOTER:"PROMOTER",
    FISSION:"FISSION"
};

export const WithdrawStatusEnum = {
    UNAPPROVED:'UNAPPROVED',
    SUCCESS:'SUCCESS',
    REVOKE:'REVOKE'
};

export const BranchEnum = {
    ZONG_XIAO:1,
    FEN_XIAO:0
}

const PaperTypeEnum = {
    ZNZJ: 1,
    MNJK: 2
};

const LiveStatusEnum = {
    NOT_START: 1,
    LIVING: 2,
    LIVE_END: 3,
    TRANSCODING: 4,
    TRANSCODE_FAIL: 5,
    TRANSCODE_SUCCESS: 6
};

const ProductTypeEnum = {
    COURSE: 1,
    BOOK: 2,
    GIFT: 3
};

const LessonTypeEnum = {
    VOD: 0,
    LIVE: 1,
    LIVE_SUPPLY: 2
};

const OrderTypeEnum = {
    PU_TONG: 1,    //普通订单
    PIN_TUAN: 2,  //拼团订单
    JI_FEN: 3,      //积分订单
    UNIVERSITY: 4,  //院校调剂包
    DISTRIBUTION:5 //分销订单
};

export  const LinkTypeEnum = {
    AD_CONTENT: 0,
    COURSE_DETAIL: 1
};

const ModuleEnum = {
    ALL: 0,
    KDSP: 1,
    PTLX: 2,
    FTSJ: 3,
    QBANK: 4,
    PAPER: 5,
    MOCK: 6,
    INTEGRAL_TASK:7,
    STCY:8,
    TKFX:9,
    ZSMK:10
};

const RoundEnum = {
    KG1:1,
    KG2:2,
    ZG:3
};

const TaskCategoryEnum = {
    RI_CHANG: 1,
    DU_XUE: 2,
    MATCH:3,
    MRYL:4
};

export const OrderStatusEnum = {
    DZF: 1,	//待支付
    DFH: 2,	//待发货
    YFH: 3,	//已发货
    YWC: 4,	//已完成
    THSQ: 5,	//退货申请
    THZ: 6,	//退货中
    YTH: 7,	//已退货
    YGB: 8,	//已关闭
    DCT: 9,  //待成团
    BFTK: 10 //部分退款
};

const TaskStatusEnum = {
    WZF: 1,  //未支付
    DCT: 2,  //待成团
    PTCG: 3, //拼团成功
    PTSB: 4  //拼团失败
};

export const RightsModuleEnum = {
    OPEN_COURSE: 171012, //开课
    CLOSE_COURSE: 171013 //关课
};

const TaskTypeEnum = {
    QIAN_DAO: 1, //签到
    KE_GUAN_TI: 2, //客观题
    ZHU_GUAN_TI: 3, //主观题
    TING_KE: 4, //听课
    PIN_TUAN: 5, //拼团
    GOU_KE: 6, //购课
    YAO_QING_HAO_YOU: 7, //邀请好友
    COMMENT: 8, //课程评价
    ZUO_YE_KG: 9, //作业-客观题
    ZUO_YE_ZG: 10, //作业-主观题
    PAN_DUAN:11, //判断题
    TIAN_KONG:12 //填空题
};

const QuestionTypeEnum = {
    KE_GUAN_TI: 1,
    ZHU_GUAN_TI: 2,
    DAN_XUAN: 4,
    DUO_XUAN: 5,
    BU_DING_XIANG: 6,
    PAN_DUAN: 7,
    TIAN_KONG: 8,
    CAILIAO_CHILD: 9,
    CAILIAO: 10,
    XUAN_ZE:11,
    JIAN_DA:12,
    LUN_SHU:13,
    FA_TIAO:14,
    AN_LI:15,
    FEN_XI:16,
    FENXI_LUNSHU:17
};

const TargetTypeEnum = {
    IDS:"IDS",
    RANGE:"RANGE"
}

export const CourseCategoryEnum = {
    XXB:1,
    ZBK:2,
    SSK:3,
    MSK:4,
    WSK:5,
    TS:6,
    FS_MFK:7,
    FS_WSK:8,
    FS_ZBK:9,
    FS_MSK:10,
    FS_TS:12,
    FK_MATCH:13,
    FS_MATCH:14
}

const PaperMakeWayEnum = {
    MANUAL:"MANUAL",
    AUTO:"AUTO"
}

const ProjectEnum = {
    FA_KAO: 1,
    FA_SHUO: 2
};

export const POP_WIDTH_BIG = "65%";
export const POP_HEIGHT_BIG = "90%";
export const POP_WIDTH_MIDDLE = 690;
export const POP_HEIGHT_MIDDLE = 640;
export const POP_WIDTH_SMALL = 600;
export const POP_HEIGHT_SMALL = 450;

function parseError(data) {
    console.log(data);
    let json = data.responseJSON;
    if(json == null){
        showMsgError("网络异常");
        return;
    }
    if(json.error === ErrorEnum.UNAUTHORIZED){
        setTimeout(function () {
            loginOut();
        },1500)
    }
    else if(json.error == ErrorEnum.INTERNAL_SERVER_ERROR){
        showMsgError("内部服务器错误");
    }
    else{
        showMsgError(json.message);
    }
}

export function checkPassword(password) {
    var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,30}');
    if (!pwdRegex.test(password)) {
        return false;
    }
    return true;
}

function parseTableError(json) {
    showMsgError(json.message);
    if(json.error === ErrorEnum.UNAUTHORIZED){
        setTimeout(function () {
            loginOut();
        },1500)
    }
}

function parseDTreeError(XMLHttpRequest) {
    var json = XMLHttpRequest.responseJSON;
    showMsgError(json.message);
    if(json.error === ErrorEnum.UNAUTHORIZED){
        setTimeout(function () {
            loginOut();
        },1500)
    }
}

function loginOut() {
    top.location = "/"
}

//获取地址栏参数
function request(name) {
    var strHref = window.document.location.href;
    var intPos = strHref.indexOf("?");
    var strRight = strHref.substr(intPos + 1);

    var arrTmp = strRight.split("&");
    for (var i = 0; i < arrTmp.length; i++) {
        var arrTemp = arrTmp[i].split("=");

        if (arrTemp[0].toUpperCase() == name.toUpperCase()) return arrTemp[1];
    }
    return "";
}

//设置搜索事件(列表页通用)
function setEvent() {
    $('#btnSearch').on('click', function () {
        getData();
        return false; //取消表单事件
    });
    $('#btnReset').on('click',function (){ setTimeout(function () {
        getData();
    },100)
    });
}

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(H)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).format("yyyy-MM-dd HH:mm:ss.S") ==> 2021-07-02 08:09:04.423
// (new Date()).format("yyyy-M-d H:m:s.S")      ==> 2021-7-2 8:9:4.18
Date.prototype.format = function(fmt){
    var o = {
        "M+" : this.getMonth()+1,                 //月份
        "d+" : this.getDate(),                    //日
        "h+" : this.getHours(),                   //小时
        "m+" : this.getMinutes(),                 //分
        "s+" : this.getSeconds(),                 //秒
        "q+" : Math.floor((this.getMonth()+3)/3), //季度
        "S"  : this.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(var k in o)
        if(new RegExp("("+ k +")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    return fmt;
}

//数组排重
function unique(arr){
    for(var i=0; i<arr.length; i++){
        for(var j=i+1; j<arr.length; j++){
            if(arr[i]==arr[j]){         //第一个等同于第二个，splice方法删除第二个
                arr.splice(j,1);
                j--;
            }
        }
    }
    return arr;
}
function setLocalProjectId(projectId) {
    sessionStorage.setItem("projectId",projectId);
}

function getLocalProjectId() {
    const projectId = sessionStorage.getItem("projectId");
    if(projectId == null){
        loginOut();
    }
    return parseInt(projectId);
}

/* 是否手机号码*/
export function isPhone(value) {
    const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (value == '' || value == undefined || value == null) {
        return false;
    } else {
        if ((!reg.test(value)) && value != '') {
            return false;
        } else {
            return true;
        }
    }
}

export function isNum(str){
    let isNum = /^\d+$/.test(str)
    return isNum;
}

export function formatSeconds(value){
    let result = parseInt(value)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

    let res = '';
    res += `${h}:`;
    res += `${m}:`;
    res += `${s}`;
    return res;
}


export {
    UrlEnum,
    ErrorEnum,
    LessonTypeEnum,
    LiveStatusEnum,
    OrderTypeEnum,
    ProjectEnum,
    TipEnum,
    QuestionTypeEnum,
    TaskTypeEnum,
    TaskCategoryEnum,
    ModuleEnum,
    RoundEnum,
    TargetTypeEnum,
    PaperMakeWayEnum,
    setLocalProjectId,
    getLocalProjectId,
    request
}
