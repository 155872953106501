import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
//修复路由重复控制台报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//路由配置
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title:'小嘟AI课 - 管理平台'
    }
  },
  {
    path: '/ad/list',
    component: ()=>import('../views/ad/List')
  },
  {
    path: '/ad/save',
    component: ()=>import('../views/ad/Save')
  },
  {
    path: '/admin/list',
    component: ()=>import('../views/admin/List')
  },
  {
    path:'/admin/reset',
    component: ()=>import('../views/admin/Reset')
  },
  {
    path: '/admin/save',
    component: ()=>import('../views/admin/Save')
  },
  {
    path:'/ai/play',
    name:'AiPlay',
    component:()=>import('../views/ai/Play')
  },
  {
    path: '/branch/list',
    name:'BranchList',
    component: ()=>import('../views/branch/List')
  },
  {
    path: '/branch/save',
    name:'BranchSave',
    component: ()=>import('../views/branch/Save')
  },
  {
    path:'/finance/withdraw/list',
    name:'BranchWithdrawList',
    component: ()=>import('../views/finance/withdraw/List')
  },
  {
    path:'/finance/withdraw/save',
    name:'BranchWithdrawSave',
    component: ()=>import('../views/finance/withdraw/Save')
  },
  {
    path:'/course/book/list',
    name:'BookList',
    component:()=>import('../views/course/book/List')
  },
  {
    path:'/course/book/save',
    name:'BookSave',
    component: ()=>import('../views/course/book/Save')
  },
  {
    path: '/course/list',
    name:'CourseList',
    component: ()=>import('../views/course/List')
  },
  {
    path: '/course/qrcode',
    name:'QrCode',
    component: ()=>import('../views/course/QrCode')
  },
  {
    path:'/course/save',
    name:'CourseSave',
    component: ()=>import('../views/course/Save')
  },
  {
    path: '/course/task-report',
    name:'TaskReport',
    component: ()=>import('../views/course/TaskReport')
  },
  {
    path: '/distribution/activity/save',
    component: ()=>import('../views/distribution/activity/Save')
  },
  {
    path: '/distribution/activity/list',
    component: ()=>import('../views/distribution/activity/List')
  },
  {
    path: '/distribution/profile/instruction',
    component: ()=>import('../views/distribution/profile/Instruction')
  },
  {
    path: '/distribution/profile/join-instruction',
    component: ()=>import('../views/distribution/profile/JoinInstruction')
  },
  {
    path: '/distribution/promoter/list',
    name:'PromoterList',
    component: ()=>import('../views/distribution/promoter/List')
  },
  {
    path: '/distribution/promoter/report',
    component: ()=>import('../views/distribution/promoter/Report')
  },
  {
    path: '/distribution/promoter/save',
    name:'PromoterSave',
    component: ()=>import('../views/distribution/promoter/Save')
  },
  {
    path: '/knowledge/list',
    name:'KnowledgeList',
    component: ()=>import('../views/knowledge/List')
  },
  {
    path:'/finance/refund/add',
    name:'AddRefund',
    component:()=>import('../views/finance/refund/Add')
  },
  {
    path: '/integral/task/save',
    name:'TaskSave',
    component: ()=>import('../views/integral/task/Save')
  },
  {
    path: '/integral/task/detail',
    name:'TaskDetail',
    component: ()=>import('../views/integral/task/Detail'),
  },
  {
    path: '/integral/task/detail-exam',
    name:'TaskDetailExam',
    component: ()=>import('../views/integral/task/DetailExam'),
  },
  {
    path:'/integral/task/list',
    name:'TaskList',
    component: ()=>import('../views/integral/task/List'),
  },
  {
    path: '/integral/task/question-record',
    name:'QuestionRecord',
    component: ()=>import('../views/integral/task/QuestionRecord')
  },
  {
    path: '/lesson/add',
    name:'AddLesson',
    component: ()=>import('../views/lesson/Add')
  },
  {
    path: '/lesson/edit',
    name:'EditLesson',
    component: ()=>import('../views/lesson/Edit')
  },
  {
    path: '/lesson/list',
    name:'LessonList',
    component: ()=>import('../views/lesson/List')
  },
  {
    path: '/lesson/play',
    name:'PlayLesson',
    component: ()=>import('../views/lesson/Play')
  },
  {
    path: '/lesson/select',
    name:'SelectLesson',
    component: ()=>import('../views/lesson/Select')
  },
  {
    path: '/order/add',
    name:'AddOrder',
    component: ()=>import('../views/order/Add')
  },
  {
    path: '/order/list',
    name:'OrderList',
    component: ()=>import('../views/order/List')
  },
  {
    path: '/order/report',
    name:'OrderReport',
    component: ()=>import('../views/order/Report')
  },
  {
    path: '/paper/mock/save',
    name:'MockPaperSave',
    component: ()=>import('../views/paper/mock/Save')
  },
  {
    path: '/paper/examRecord',
    name:'ExamRecord',
    component: ()=>import('../views/paper/ExamRecord')
  },
  {
    path: '/paper/type/edit',
    name:'EditPaperType',
    component: ()=>import('../views/paper/type/Edit')
  },
  {
    path: '/paper/share/qrCode',
    name:'PaperQrCode',
    component: ()=>import('../views/paper/share/QrCode')
  },
  {
    path: '/paper/share/save',
    name:'PaperSave',
    component: ()=>import('../views/paper/share/Save')
  },
  {
    path: '/push/save',
    name:'PushSave',
    component: ()=>import('../views/push/Save')
  },
  {
    path: '/question/cl/add',
    name:'ClAdd',
    component: ()=>import('../views/question/cl/Add')
  },
  {
    path:'/question/cl/edit',
    name:'ClEdit',
    component: ()=>import('../views/question/cl/Edit')
  },
  {
    path:'/question/jd/add',
    name:'JdAdd',
    component: ()=>import('../views/question/jd/Add')
  },
  {
    path:'/question/jd/edit',
    name:'JdEdit',
    component: ()=>import('../views/question/jd/Edit')
  },
  {
    path: '/question/pd/add',
    name:'PdAdd',
    component: ()=>import('../views/question/pd/Add')
  },
  {
    path:'/question/pd/edit',
    name:'PdEdit',
    component: ()=>import('../views/question/pd/Edit')
  },
  {
    path: '/question/select',
    name:'SelectQuestion',
    component: ()=>import('../views/question/Select')
  },
  {
    path:'/question/tk/add',
    name:'TkAdd',
    component: ()=>import('../views/question/tk/Add')
  },
  {
    path: '/question/tk/edit',
    name:'TkEdit',
    component: ()=>import('../views/question/tk/Edit')
  },
  {
    path: '/question/xz/add',
    name:'XzAdd',
    component: ()=>import('../views/question/xz/Add')
  },
  {
    path:'/question/xz/edit',
    name:'XzEdit',
    component: ()=>import('../views/question/xz/Edit')
  },
  {
    path: '/question/qrcode',
    name:'QrCode',
    component: ()=>import('../views/question/QrCode')
  },
  {
    path:'/resource/category/add',
    name:'AddResourceCategory',
    component:()=>import('../views/resource/category/Add')
  },
  {
    path:'/resource/category/edit',
    name:'EditResourceCategory',
    component:()=>import('../views/resource/category/Edit')
  },
  {
    path: '/resource/category/list',
    name:'ResourceCategoryList',
    component: ()=>import('../views/resource/category/List')
  },
  {
    path: '/resource/list',
    name: 'ResourceList',
    component: ()=>import('../views/resource/List')
  },
  {
    path: '/role/list',
    name:'RoleList',
    component: ()=>import('../views/role/List')
  },
  {
    path: '/role/save',
    name:'RoleSave',
    component: ()=>import('../views/role/Save')
  },
  {
    path:'/student/add',
    name:'AddStudent',
    component: ()=>import('../views/student/Add')
  },
  {
    path:'/student/list',
    name:'StudentList',
    component: ()=>import('../views/student/List')
  },
  {
    path: '/student/lesson-record/list',
    name:'LessonRecord',
    component: ()=>import('../views/student/lesson-record/List')
  },
  {
    path: '/student/question-record/list',
    name:'QuestionRecordList',
    component: ()=>import('../views/student/question-record/List')
  },
  {
    path:'/student/question-record/view-cl',
    name:'ViewCl',
    component:()=>import('../views/student/question-record/ViewCl')
  },
  {
    path:'/student/withdraw/list',
    name:'StudentWithdrawList',
    component:()=>import('../views/student/withdraw/List')
  },
  {
    path:'/student/withdraw/save',
    name:'StudentWithdrawSave',
    component: ()=>import('../views/student/withdraw/Save')
  },
  {
    path:'/sys/baijia/callback',
    name:'Callback',
    component:()=>import('../views/baijia/Callback')
  },
  {
    path: '/sys/profile/daily-instruction',
    name:'DailyPracticeRewardInstruction',
    component: ()=>import('../views/sys/profile/DailyInstruction')
  },
  {
    path: '/sys/profile/supplement',
    name:'Supplememt',
    component: ()=>import('../views/sys/profile/Supplement')
  },
  {
    path: '/tb/activity/list',
    name:'ActivityList',
    component: ()=>import('../views/tb/activity/List')
  },
  {
    path: '/teach/subject/video/list',
    name:'VideoList',
    component: ()=>import('../views/teach/subject/video/List')
  },
  {
    path: '/teach/subject/video/save',
    name:'VideoSave',
    component: () => import('../views/teach/subject/video/Save')
  },
  {
    path:'/teach/university/add',
    name:'AddUniversity',
    component:()=>import('../views/teach/university/Add')
  },
  {
    path:'/teach/university/edit',
    name:'EditUniversity',
    component:()=>import('../views/teach/university/Edit')
  },
  {
    path:'/teach/university/category/add',
    name:'AddUniversityCategory',
    component:()=>import('../views/teach/university/category/Add')
  },
  {
    path:'/teach/university/category/edit',
    name:'EditUniversityCategory',
    component:()=>import('../views/teach/university/category/Edit')
  },
  {
    path:'/teach/university/score/edit',
    name:'EditUniversityScore',
    component:()=>import('../views/teach/university/score/Edit')
  },
  {
    path:'/teach/university/adjust/add',
    name:'AddUniversityAdjust',
    component:()=>import('../views/teach/university/adjust/Add')
  },
  {
    path:'/teach/university/adjust/edit',
    name:'EditUniversityAdjust',
    component:()=>import('../views/teach/university/adjust/Edit')
  },
  {
    path:'/teacher/list',
    name:'TeacherList',
    component: ()=>import('../views/teacher/List')
  },
  {
    path: '/teacher/save',
    name:'TeacherSave',
    component: ()=>import('../views/teacher/Save')
  },

  {
    path:'/teach/subject/add',
    name:'AddSubject',
    component:()=>import('../views/teach/subject/Add')
  },
  {
    path:'/teach/subject/edit',
    name:'EditSubject',
    component:()=>import('../views/teach/subject/Edit')
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
